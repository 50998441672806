import { createReducer, on } from '@ngrx/store';
import { productSubstitutesActions } from '../actions/product-substitutes.actions';
import { Product } from '@app/store/models';

export interface ProductSubstitutesState {
    items: {
        no: string;
        items: Product[];
        loading: boolean;
        loaded: boolean;
    }[];
}

export const initialState: ProductSubstitutesState = {
    items: [],
};

export const productSubstitutesReducer = createReducer(
    initialState,

    on(productSubstitutesActions.getProducts, (state, { no }): ProductSubstitutesState => {
        const items = [...state.items];
        const itemIndex = state.items.findIndex((item) => item.no === no);

        if (itemIndex === -1) {
            items.push({
                no: no,
                items: [],
                loading: false,
                loaded: false,
            });

            return { ...state, items };
        }

        if (items[itemIndex].loading === false) {
            items.splice(itemIndex, 1, {
                ...items[itemIndex],
                loading: false,
            });

            return { ...state, items };
        }

        return state;
    }),

    on(productSubstitutesActions.getProductsCallback, (state, { no }): ProductSubstitutesState => {
        const items = [...state.items];
        const itemIndex = state.items.findIndex((item) => item.no === no);

        if (itemIndex !== -1) {
            items.splice(itemIndex, 1, {
                ...items[itemIndex],
                loading: true,
            });

            return { ...state, items };
        }

        return state;
    }),

    on(productSubstitutesActions.getProductsSuccess, (state, actino): ProductSubstitutesState => {
        const items = [...state.items];
        const itemIndex = state.items.findIndex((item) => item.no === actino.no);

        if (itemIndex !== -1) {
            items.splice(itemIndex, 1, {
                ...items[itemIndex],
                items: actino.items,
                loading: false,
                loaded: true,
            });

            return { ...state, items };
        }

        return state;
    }),

    on(productSubstitutesActions.getProductsError, (state, { no }): ProductSubstitutesState => {
        const items = [...state.items];
        const itemIndex = state.items.findIndex((item) => item.no === no);

        if (itemIndex !== -1) {
            items.splice(itemIndex, 1, {
                ...items[itemIndex],
                items: [],
                loading: false,
            });

            return { ...state, items };
        }

        return state;
    }),

    on(
        productSubstitutesActions.clear,
        (): ProductSubstitutesState => ({
            ...initialState,
        }),
    ),
);
