import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { UserFacade } from '@app/store/user';
import { BlockadesService } from '../../services/blockades.service';
import { BehaviorSubject, Subscription, filter, map, tap } from 'rxjs';
import { UserBlocked } from '@app/store/models';
import { Grow } from '@app/shared/animations';

@Component({
    selector: 'app-limit',
    templateUrl: './limit.component.html',
    styleUrls: ['./limit.component.scss'],
    animations: [Grow],
})
export class LimitComponent implements OnInit, OnDestroy {
    private readonly userFacade = inject(UserFacade);
    private readonly blockadesService = inject(BlockadesService);
    readonly type = 'Limit';

    setStorageKey$ = new BehaviorSubject<{ type: UserBlocked | null; id: string | null }>({
        type: this.type,
        id: null,
    });

    id$ = this.userFacade.user$.pipe(
        map((user): string => user.salespersonInfo?.no ?? ''),
        tap((id) =>
            this.setStorageKey$.next({
                ...this.setStorageKey$.value,
                id,
            }),
        ),
    );

    private readonly subscriptions$ = new Subscription();

    get isClosed() {
        return this.blockadesService.isClosed;
    }

    ngOnInit(): void {
        this.subscriptions$.add(
            this.setStorageKey$
                .pipe(
                    filter((value): value is { type: UserBlocked; id: string } => value.id !== null && value.type !== null),
                    tap(({ type, id }) => this.blockadesService.setStorageKey(type, id)),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    close() {
        this.blockadesService.close();
    }
}
