import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ProductSubstitutesFacade } from '../facades/product-substitutes.facade';

export function productsSubstitutesCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const productSubstitutesFacade = inject(ProductSubstitutesFacade);
        productSubstitutesFacade.clear();
        return true;
    };
}
