import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map } from 'rxjs';
import { Product, ProductDto } from '@app/store/models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    getProductSubstitutes(id: string) {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<ProductDto[]>>(`api/v1/suggest/items/${id}`)
            .pipe(map(({ data }) => data.map((dto) => new Product(dto))));
    }
}
