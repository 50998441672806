import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PRODUCT_SUBSTITUTES_FEATURE_KEY } from '../keys';
import { ProductSubstitutesState } from '../reducers/product-substitutes.reducer';

const selectState = createFeatureSelector<ProductSubstitutesState>(PRODUCT_SUBSTITUTES_FEATURE_KEY);

const selectItems = (no: string) => createSelector(selectState, (state) => state.items.find((item) => item.no === no)?.items ?? []);
const selectItem = (no: string) => createSelector(selectState, (state) => state.items.find((item) => item.no === no) ?? null);
const selectItemLoading = (no: string) => createSelector(selectState, (state) => state.items.find((item) => item.no === no)?.loading ?? false);
const selectSubstitutesAvailable = (no: string) => createSelector(selectState, (state) => (state.items.find((item) => item.no === no)?.items || []).length > 0);

export { selectItems, selectItem, selectItemLoading, selectSubstitutesAvailable };
