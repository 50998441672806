import { Injectable, inject } from '@angular/core';
import { map, filter, mergeMap, catchError, of, from, merge, takeUntil, repeat } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { productSubstitutesActions } from '../actions/product-substitutes.actions';
import { HttpService } from '../services/http.service';
import { Store } from '@ngrx/store';
import { selectItem } from '../selectors/product-substitutes.selector';
import { shoppingCartActions } from '@app/store/shopping-cart/actions/shopping-cart.actions';
import { shippingAddressesActions } from '@app/store/shipping-addresses';

@Injectable()
export class ProductSubstitutesEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly store = inject(Store);

    private stopConditions$ = merge(this.actions$.pipe(ofType(shippingAddressesActions.setAddress, shoppingCartActions.clear)));

    getProductSubstitutesTriggers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shoppingCartActions.updateCartSuccess, shoppingCartActions.getCartSuccess),
            mergeMap(({ cart }) => from(cart.map((item) => productSubstitutesActions.getProducts({ no: item.no })))),
            takeUntil(this.stopConditions$),
            repeat(),
        );
    });

    getProductSubstitutes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productSubstitutesActions.getProducts),
            concatLatestFrom(({ no }) => this.store.select(selectItem(no)).pipe(filter((item) => item?.loading === false && item?.loaded === false))),
            mergeMap(([{ no }]) => {
                return this.httpService.getProductSubstitutes(no).pipe(
                    map((items) => productSubstitutesActions.getProductsSuccess({ no, items })),
                    catchError(() => of(productSubstitutesActions.getProductsError({ no }))),
                );
            }, 2),
            takeUntil(this.stopConditions$),
            repeat(),
        );
    });

    getProductSubstitutesCallback$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productSubstitutesActions.getProducts),
            map(({ no }) => productSubstitutesActions.getProductsCallback({ no })),
            takeUntil(this.stopConditions$),
            repeat(),
        );
    });

    cleanupOnStopConditions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shippingAddressesActions.setAddress, shoppingCartActions.clear),
            map(() => productSubstitutesActions.clear()),
        );
    });
}
