import { Injectable, inject } from '@angular/core';
import { PriceTotalPipe } from '@app/modules/cart/pipes/total-price.pipe';
import { LocalStorage } from '@app/shared/classes';
import { UserBlocked } from '@app/store/models';
import { combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import { ShoppingCartFacade } from '@app/store/shopping-cart';
import { UserFacade } from '@app/store/user';
import { User } from '@app/store/user/models';

@Injectable({
    providedIn: 'root',
})
export class BlockadesService {
    private readonly userFacade = inject(UserFacade);
    private readonly shoppingCartFacade = inject(ShoppingCartFacade);
    private readonly totalPipe = inject(PriceTotalPipe);
    readonly closeKey = 'close';
    private storageKey = '';

    private readonly limitFeatureEnabled$ = this.userFacade.features$.pipe(map((features) => features.includes('merchant-limit')));

    private readonly limitVerificationEnabled$ = combineLatest([this.userFacade.skipCreditLimitVerification$, this.limitFeatureEnabled$]).pipe(
        filter(([skip, limitFeatureEnabled]) => skip === false && limitFeatureEnabled === true),
        map(([skip, limitFeatureEnabled]) => skip === false && limitFeatureEnabled === true),
        distinctUntilChanged(),
    );

    private readonly limitExceeded$ = combineLatest([
        this.userFacade.user$,
        this.shoppingCartFacade.cart$.pipe(map((cart) => this.totalPipe.transform(cart))),
    ]).pipe(
        filter((value): value is [User, number] => value[0].salespersonInfo?.creditLimitLcy !== undefined),
        map(([user, price]) =>
            user.salespersonInfo?.creditLimitLcy !== undefined && user?.salespersonInfo !== null ? user.salespersonInfo.creditLimitLcy <= price : false,
        ),
        distinctUntilChanged(),
    );

    creditLimitExceeded$ = combineLatest([this.limitVerificationEnabled$, this.limitExceeded$]).pipe(
        map((conditions) => conditions.every((condition) => condition === true)),
    );

    get isClosed() {
        return LocalStorage.read(this.storageKey) === this.closeKey;
    }

    setStorageKey(type: UserBlocked, id: string) {
        this.storageKey = type + id;
    }

    close() {
        LocalStorage.write(this.storageKey, this.closeKey);
    }

    clear() {
        if (this.storageKey === '') return;
        LocalStorage.write(this.storageKey, '');
        this.storageKey = '';
    }
}
