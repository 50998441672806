import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { productSubstitutesActions } from '../actions/product-substitutes.actions';
import { selectItemLoading, selectItems, selectSubstitutesAvailable } from '../selectors/product-substitutes.selector';

@Injectable({
    providedIn: 'root',
})
export class ProductSubstitutesFacade {
    private store = inject(Store);

    items$ = (no: string) => this.store.select(selectItems(no));
    itemsAvailable$ = (no: string) => this.store.select(selectSubstitutesAvailable(no));
    itemLoading$ = (no: string) => this.store.select(selectItemLoading(no));

    getItems(no: string) {
        this.store.dispatch(productSubstitutesActions.getProducts({ no }));
    }

    clear(): void {
        this.store.dispatch(productSubstitutesActions.clear());
    }
}
