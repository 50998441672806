import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PRODUCT_SUBSTITUTES_FEATURE_KEY } from './keys';
import { productSubstitutesReducer } from './reducers/product-substitutes.reducer';
import { ProductSubstitutesEffects } from './effects/product-substitutes.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(PRODUCT_SUBSTITUTES_FEATURE_KEY, productSubstitutesReducer),
        EffectsModule.forFeature([ProductSubstitutesEffects]),
    ],
})
export class ProductSubstitutesStoreModule {}
